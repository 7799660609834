<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
    <div class="home-welcome text-center">
      <h2>Credit Application</h2>
    </div>
  </section>
  <section class="second-section mt-5">
    <div class="container">
      <div class="row">
        <div class="col-10 mx-auto">
          <div class="d-flex text-center">
            <a
              :href="base + 'pdf/Company_Credit_Application.pdf'"
              target="_blank"
              ><fa :icon="['fas', 'users']" /> Download Company Credit
              Application</a
            >
            <a
              :href="base + 'pdf/Personal_Credit_Application.pdf'"
              target="_blank"
              ><fa :icon="['fas', 'user']" /> Download Personal Credit
              Application
            </a>
          </div>
          <div class="mt-4 text-center">
            <p class="fw-bold">Please send your application to:</p>
            <p><span class="fw-bold">Fax:</span> (312) 678-0974</p>
            <p><span class="fw-bold">Email:</span> eandatrucksales@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
export default {
  data() {
    return {
      base: process.env.VUE_APP_BASE_URL,
    };
  },
};
</script>

<style scoped>
div.d-flex a {
  flex: 1;
  font-weight: 600;
  font-size: 1.1rem;
}
</style>
